<template>
  <div class="container mt-2 card shadow py-4 px-6">

  </div>
</template>

<script>
export default {
    name: 'Privacy Policy'
};
</script>

<style>
</style>